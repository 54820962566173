import { BrowserRouter, Switch, Route } from "react-router-dom";
import './App.css';
import Home from "./components/Home/Home";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs/AboutUs";
import Team from "./components/Team/Team";
import Services from "./components/Services/Services";
import Review from "./components/Reviews";
import PageNotFound from "./components/PageNotFound";
import Gallery from "./components/Gallery/Gallery";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/home" exact component={Home}/>
        <Route path='/contactus' exact component={ContactUs} />
        <Route path='/aboutus' exact component={AboutUs} />
        <Route path='/services' exact component={Services} />        
        <Route path='/team' exact component={Team} />
        <Route path='/reviews' exact component={Review} />
        <Route path='/gallery' exact component={Gallery} />
        <Route path='*' exact={true} component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
