import React, { useState, useLayoutEffect } from "react";
import emailjs from 'emailjs-com'
import Menu from "./Menu";
import Footer from './Footer'
import logo from "./whatsapp.png";
import { Link } from "react-router-dom";

const Layout = ({ className, children }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState('');
    const [type, setType] = useState('New Patient');
    const [slot, setSlot] = useState('Morning');
    const [message, setMessage] = useState('');

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    }

    const handleDateChange = (e) => {
        setDate(e.target.value);
    }

    const handleTypeChange = (e) => {
        setType(e.target.value);
    }

    const handleSlotChange = (e) => {
        setSlot(e.target.value);
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, phone, date, type, slot, message);
        emailjs.send("service_mt6qxqe", "template_if6tq83", {
            name: name,
            email: email,
            phone: phone,
            date: date,
            type: type,
            slot: slot,
            message: message,
        }, 'user_uXgJaxhfyJ1WIZUWmM5ZU');
        setName('');
        setEmail('');
        setPhone('');
        setDate('');
        setType('');
        setSlot('');
        setMessage('');
    }

    return (
        <div>
            <Menu />
            <div className={className} style={{ minHeight: '80vh', marginTop: '13vh' }}>{children}</div>
            <button className="btn btn-lg book" style={{ zIndex: '100' }} data-toggle="modal" data-target=".bs-example-modal-new">Book Now</button>
            <div className="modal fade bs-example-modal-new" tabindex="-1" id='model' role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header booking">
                            <h4 className="modal-title text-light fw-bold" id="gridSystemModalLabel">Request an Appointment</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ color: 'white' }}><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body booking">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <input value={name} type="text" className="form-control mb-3" required id="p1firstname" placeholder='Full Name' onChange={handleNameChange} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-3">
                                        <input value={email} type="email" className="form-control" required id="p2firstname" placeholder='Email' onChange={handleEmailChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mb-3">
                                        <input value={phone} type="number" className="form-control " required id="p2lastname" placeholder='Phone Number' onChange={handlePhoneChange} />
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-3">
                                        <input value={date} placeholder="Date" className="form-control" required type="text" onFocus={(e) => e.currentTarget.type = 'date'} id="date" onChange={handleDateChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-6 mb-3'>
                                        <label className="form-control">
                                            <input type="radio" name="optradio" value='New Patient' checked onChange={handleTypeChange} /> A New Patient
                                        </label>
                                        <label className="form-control">
                                            <input type="radio" name="a" checked value='Morning' onChange={handleSlotChange} /> Morning
                                        </label>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label className="form-control">
                                            <input type="radio" name="optradio" value='Existing Patient' onChange={handleTypeChange} /> Existing Patient
                                        </label>
                                        <label className="form-control">
                                            <input type="radio" name="a" value='Evening' onChange={handleSlotChange} /> Evening
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <textarea value={message} className="form-control" placeholder='Message' onChange={handleMessageChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <button type='submit' className="btn btn-outline-light" data-dismiss="modal" onClick={handleSubmit} >Send Enquiry</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <Link to={{ pathname: "https://api.whatsapp.com/send/?phone=+919426667771" }} target="_blank"><img src={logo} style={{ position: 'fixed', height: '10vh', width: '10vh', bottom: '10px', left: '10px', zIndex:'100' }} alt='' /></Link>
            <Footer />
        </div>
    );
}

export default Layout;