import React from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import not from "./not.jpg";

const PageNotFound = () => {
    return (
        <Layout>
            <div className='container' style={{marginTop:'10%'}}>
                <div className=''>
                    <img src={not} alt='' style={{height:'30vh', width:'50vh'}} className='center' />
                    <h2 className="text-center">Oh no!!</h2>
                    <p className="text-center">
                        You’re either misspelling the URL <br /> or requesting a page that's no longer here.
                    </p>
                    <div align="center">
                        <form>
                        <button className="btn btn-outline-light booking"><Link to='/' style={{color:'inherit' ,textDecoration:'none'}}>Back to Home page</Link></button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PageNotFound