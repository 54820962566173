import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="col-md-12 text-center">
          <ul className="footer-links row">
            <li className='col-sm-12 col-md-6 col-lg-4'>
              <h4 className='mb-4'>Working Hours</h4><hr style={{ width: '20%' }} /> Monday to Saturday<br />
              <i className="far fa-clock"></i> 10:00 AM to 8:00 PM<br />
               Sunday (On Prior Appointment) <br/><i className="far fa-clock"></i> 10:00 AM to 12:00 Noon
            </li>
            <li className='col-sm-12 col-md-6 col-lg-4'>
              <h4 className='mb-4'>Contact Us</h4><hr style={{ width: '20%' }} />
              <a href="tel:+919426667771"><i className="fas fa-phone-volume"></i> +91 94266 67771</a><br />
              <a href="tel:07948977777"><i className="fas fa-phone-volume"></i> 079 4897-7777</a><br />
              <a href="tel:07948977777"><i className="fas fa-phone-volume"></i> +91 94280-00040</a><br />
              <a href="mailto:info@onesmiledentalstudio.in"><i className="fas fa-envelope mb-3"></i> info@onesmiledentalstudio.in</a>
            </li>
            <li className='col-sm-12 col-md-6 col-lg-4'>
              <h4 className='mb-4'>Reach Us</h4><hr style={{ width: '20%' }} />
              <i className="fas fa-address-card"></i> A-404, Iscon center, Above TBZ jewellers, Nr. HP Petrol Pump, Shivranjani Cross Roads, Satellite, Ahmedabad - 380015
            </li>
          </ul>
        </div>
        <hr />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; 2021 All Rights Reserved by
              <Link className='loogo' to="/" style={{ color: '#9bd7b5' }}> One Smile Dental Studio</Link>.
            </p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><Link className="facebook" to={{ pathname: "https://www.facebook.com/onesmiledentalstudio/" }} target="_blank"><span className="fa fa-facebook"></span></Link></li>
              <li><Link className="dribbble" to={{ pathname: "https://instagram.com/onesmiledentalstudio" }} target="_blank"><span className="fa fa-instagram"></span></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer