import React from "react";
import Layout from "../Layout";
import a from './Website clinic pics/a.webp'
import b from './Website clinic pics/b.webp'
import c from './Website clinic pics/c.webp'
import d from './Website clinic pics/d.webp'
import e from './Website clinic pics/e.webp'
import f from './Website clinic pics/f.webp'
import g from './Website clinic pics/g.webp'
import h from './Website clinic pics/h.webp'
import i from './Website clinic pics/i.webp'
import j from './Website clinic pics/j.webp'
import k from './Website clinic pics/k.webp'
import l from './Website clinic pics/l.webp'
import m from './Website clinic pics/m.webp'
import n from './Website clinic pics/IMG_4656.webp'
import o from './Website clinic pics/IMG_1375.webp'
import p from './Website clinic pics/IMG_1995.webp'
import q from './Website clinic pics/IMG_4486.webp'
import r from './Website clinic pics/IMG_2907.webp'
import s from './Website clinic pics/s.webp'
import t from './Website clinic pics/t.webp'
import u from './Website clinic pics/u.webp'
import v from './Website clinic pics/v.webp'
import w from './Website clinic pics/w.webp'
import x from './Website clinic pics/x.webp'

const Gallery = () => {
    return (
        <Layout>
            <div className="Banner">
                <div className="container">
                    <h1 className="display-4">Gallery</h1>
                    <p className="lead">One Smile Dental Studio</p>
                </div>
            </div>
            <div className="container-fluid aa">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <img src={a} className="img-fluid" alt='' />
                        <img src={b} className="img-fluid" alt='' />
                        <img src={k} className="img-fluid" alt='' />
                        <img src={n} className="img-fluid" alt='' />
                        <img src={x} className="img-fluid" alt='' />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <img src={e} className="img-fluid" alt='' />
                        <img src={g} className="img-fluid" alt='' />
                        <img src={u} className="img-fluid" alt='' />
                        <img src={l} className="img-fluid" alt='' />
                        <img src={p} className="img-fluid" alt='' />
                        <img src={q} className="img-fluid" alt='' />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <img src={d} className="img-fluid" alt='' />
                        <img src={c} className="img-fluid" alt='' />
                        <img src={m} className="img-fluid" alt='' />
                        <img src={v} className="img-fluid" alt='' />
                        <img src={r} className="img-fluid" alt='' />
                        <img src={t} className="img-fluid" alt='' />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <img src={f} className="img-fluid" alt='' />
                        <img src={h} className="img-fluid" alt='' />
                        <img src={s} className="img-fluid" alt='' />
                        <img src={j} className="img-fluid" alt='' />
                        <img src={i} className="img-fluid" alt='' />
                        <img src={o} className="img-fluid mb-3" alt='' />
                        <img src={w} className="img-fluid mb-3" alt='' />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Gallery