import React from "react";
import Layout from "./Layout";

const Review = () => {
    return (
        <Layout>
            <div className="Banner mb-5">
                <div className="container">
                    <h1 className="display-4">Reviews</h1>
                    <p className="lead">One Smile Dental Studio</p>
                </div>
            </div>
            <div className='container'>
                <section id="testimonials">
                    <div className='row'>
                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Riddhi Chauhan</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>Best in Ahemdabad…. Dr ashil is so kind and gentle. Music is also calm and soothing. Staff is humble and also all the safety precautions are held😍😍totally worth it. If you want to have a dental check up or treatment it is the best place to go.</p>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Mohit Pratap</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>Went here for my bi-annual teeth cleaning. Seamless procedure with all Covid Protocols in place, and that was definitely re-assuring. Have known Dr Ashil Shah for a few years now and his consultation and regular check ups have helped me in keeping a good oral hygiene.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Harsh Shah</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>It is very difficult to find trustworthy place for dental treatments. One Smile is that trustworthy dental clinic. Dr Ashil listens and understands the problem thoroughly and provides correct advise for the treatment.</p>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Ankul Desai</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>This clinic is the best clinic in ahemdabad. Doctor Ashil is a very good experienced doctor. I am very happy with the treatment of Dr. Ashil and I tell everyone to visit this clinic once in a while. Thank you sir.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Moushumi Banerjee</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>Extremely well behaved and good doctor...makes the patient feel so comfortable and nice....best place to visit for any of your dental problems.</p>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Vijay Desai</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>It's a really depandable place to be for your dental problems...comfortable, clean, ambiance. Doctor Ashil is a real magician😎 …</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Milan Patel</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>It was very Nice experience and very satisfactory result of treatment. Clinic is well maintained with all hygiene parameters. The way Dr. Ashil treat his patients is really awesome.</p>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Krisha Shah</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>It's amazing! Must visit this studio. Dr.Ashil does really a great job. His ability and dedication towards his work is commendable. Well structured, hygienic, proper sanitation.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-5'>
                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Santosh Bhati</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>It was great experience. My papa is very satisfied  with quality of service  and friendly behavior.</p>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-box-container col-lg-6">
                            <div class="testimonial-box">
                                <div class="box-top">
                                    <div class="profile">
                                        <div class="name-user">
                                            <strong>Nisarg Bhavsar</strong>
                                        </div>
                                    </div>
                                    <div class="reviews-stars">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </div>
                                </div>
                                <div class="client-comment">
                                    <p>The best place to resolve your all dental problems in a very aesthetic way at affordable cost.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section>
            </div>
        </Layout>
    )
}

export default Review