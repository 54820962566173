import React from "react";
import Layout from "../Layout";
import mission from "./mission.webp";
import vision from "./vision.webp";
import tech from '../Home/slide3.webp'
import hygiene from './hygiene.webp'

const AboutUs = () => {
    return (
        <Layout>
            <div className="Banner mb-5">
                <div className="container">
                    <h1 className="display-4">About Us</h1>
                    <p className="lead">One Smile Dental Studio</p>
                </div>
            </div>
            <section className="why-us section-bg mb-5" data-aos="fade-up" date-aos-delay="200">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                        <iframe className='mt-5 ml-2' width="100%" height="300vh" src="https://www.youtube.com/embed/M2pa-P-GNP0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="col-lg-6 d-flex flex-column justify-content-center p-5">
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-stethoscope"></i></div>
                                <h4 className="title"><a href="/">One Smile Dental Studio</a></h4>
                                <p className="description text-justify">Our Dental Studio is a goto name for all your dental problems. Our multispeciality clinic has the best in class equipment, latest dental tools, and experienced dental professionals. Our practice is distinguished among others by sharing a reputation for providing exceptional services and up-to-date expertise to each and every patient that we receive. Adhering to the Global and International guidelines and protocols in Dentistry and Oral Health care, we plan our treatments and services using only the best in industry standards. </p>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <section className="service-details mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-stretch" data-aos="fade-up">
                            <div className="card">
                                <div className="card-img">
                                    <img src={mission} alt="..." style={{ height: '50vh', width: '100%' }} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title"><a href='.'>Our Mission</a></h5>
                                    <p className="card-text text-justify">We at One Smile Dental Studio believe in only one mission. One mission to give every person one more reason to smile. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-stretch" data-aos="fade-up">
                            <div className="card">
                                <div className="card-img">
                                    <img src={vision} alt="..." style={{ height: '50vh', width: '100%' }} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title"><a href='.'>Our Vision</a></h5>
                                    <p className="card-text text-justify">We at One Smile Dental Studio believe in spreading one beautiful smile, one healthy smile, one marvelous smile, one bright smile, one magnificent smile and that is our vision, every person should own their smile no matter what.</p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 d-flex align-items-stretch" data-aos="fade-up">
                            <div className="card">
                                <div className="card-img">
                                    <img src={tech} alt="..." style={{ height: '50vh', width: '100%' }} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title"><a href='.'>Latest Technology</a></h5>
                                    <p className="card-text text-justify">At One Smile Dental Studio, we have very latest advantages in dental technology and equipment. Our team is continually trained in the use of advanced technology and equipment, and in clinical best practices.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-stretch" data-aos="fade-up">
                            <div className="card">
                                <div className="card-img">
                                    <img src={hygiene} alt="..." style={{ height: '50vh', width: '100%' }} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title"><a href='.'>Hygiene</a></h5>
                                    <p className="card-text text-justify">We follow strict hygiene control on a daily basis. Right from the cleanliness of the clinic, disinfection of surgical space including chairs and nearby areas to the sterilization of all instruments.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default AboutUs