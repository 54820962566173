import React, { useState } from "react";
import Layout from "./Layout";
import emailjs from 'emailjs-com'


const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name,email,message);
        emailjs.send("service_mt6qxqe","template_ctv0rrw",{
            name: name,
            email: email,
            message: message,
            },'user_uXgJaxhfyJ1WIZUWmM5ZU');
            setName('');
            setEmail('');
            setMessage('');
    }

    return (
        <Layout>
            <div className="Banner mb-5">
                <div className="container">
                    <h1 className="display-4">Contact Us</h1>
                    <p className="lead">One Smile Dental Studio</p>
                </div>
            </div>
            <div className='row m-5'>
                <div className='col-md-7 col-sm-12'>
                    <div className='mapp'>
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.9928107050187!2d72.52754501496784!3d23.024036184952372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85c1770f48ef%3A0xa6e00e1a4f4d9df!2sOne%20Smile%20Dental%20Studio!5e0!3m2!1sen!2sin!4v1629349271248!5m2!1sen!2sin" style={{ height: '100%', width: '100%' }} loading="lazy"></iframe>
                    </div>
                </div>
                <div className='col-md-5 col-sm-12'>
                    <div className="content">
                        <div className="left-side">
                            <div className="address details mb-5">
                                <i className="fas fa-map-marker-alt"></i>
                                <div className="topic">Address</div>
                                <div className="text-one">A-404, Iscon Center, Shivranjani Cross Road, </div>
                                <div className="text-two">Satellite, Ahmedabad, Gujarat - 380015</div>
                            </div>
                            <div className="phone details mb-5">
                                <i className="fas fa-phone-alt"></i>
                                <div className="topic">Phone</div>
                                <div className="text-two">+91-9426667771</div>
                                <div className="text-two">079 4897-7777</div>
                                <div className="text-one">+91-9428000040</div>
                            </div>
                            <div className="email details mb-5">
                                <i className="fas fa-envelope"></i>
                                <div className="topic">Email</div>
                                <div className="text-two">info@onesmiledentalstudio.in</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' jumbotron jumbotron-fluid booking'>
                <form action="#RegistrationDataBase" className='container'>
                    <h3 className="text-light fw-bold mb-4">Let Us know your experience</h3>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <input type="text" onChange={handleNameChange} className="form-control mb-3" id="p1firstname" placeholder='Full Name' />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                            <input type="email" onChange={handleEmailChange} className="form-control " id="p2firstname" placeholder='Email' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <textarea rows='7' onChange={handleMessageChange} className="form-control" placeholder='Message' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-outline-light" onClick={handleSubmit} >Submit</button>
                        </div>
                    </div>
                </form>
            </div >
        </Layout>
    )
}

export default ContactUs