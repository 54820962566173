import React from "react";
import { Link } from "react-router-dom";
import logo from './logo.jpeg'

const Menu = () => {
    return (
        <nav className="navbar navbar-expand-lg fixed-top text-uppercase" role="navigation" style={{zIndex:'50'}}>
            <Link to='/' className='navbar-brand ml-4 mr-3'><img src={logo} style={{ height: '10vh', width: '14vh' }} alt='logo' /></Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars foo"></i>
            </button>
            <div className="collapse navbar-collapse ml-3 mr-5" id="navbarNavDropdown">
                <ul className="navbar-nav ml-auto mr-5">
                    <li className="nav-item active">
                        <Link to='/' className='nav-link heading font-weight-bold'>Home</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <Link to='#' className="nav-link dropdown-toggle heading font-weight-bold" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Why Choose Us
                        </Link>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link className="dropdown-item heading font-weight-bold" to='/aboutus'><b>About Us</b></Link>
                            <Link className="dropdown-item heading font-weight-bold" to='/team'><b>Team</b></Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <Link className="nav-link heading font-weight-bold" to='/services'>
                            Services
                        </Link>
                    </li>
                    <li className="nav-item dropdown">
                        <Link className="nav-link heading font-weight-bold" to='/gallery'>
                            Gallery
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link heading font-weight-bold" to='/reviews'>Patient Review</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link heading font-weight-bold" to='/contactus'>Contact Us</Link>
                    </li>
                    <li className="nav-item">
                    
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Menu