import React, { useState } from "react";
import emailjs from 'emailjs-com'
import Layout from "../Layout";
import ban1 from './ban1.webp'
import ban2 from './ban2.webp'
import ban3 from './ban3.webp'
import slide1 from './slide1.webp'
import slide2 from './slide2.webp'
import slide3 from './slide3.webp'

const Home = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState('');
    const [type, setType] = useState('New Patient');
    const [slot, setSlot] = useState('Morning');
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    }

    const handleDateChange = (e) => {
        setDate(e.target.value);
    }

    const handleTypeChange = (e) => {
        setType(e.target.value);
    }

    const handleSlotChange = (e) => {
        setSlot(e.target.value);
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name,email,phone,date,type,slot,message);
        emailjs.send("service_mt6qxqe","template_if6tq83",{
            name: name,
            email: email,
            phone: phone,
            date: date,
            type: type,
            slot: slot,
            message: message,
            },'user_uXgJaxhfyJ1WIZUWmM5ZU');
            setName('');
            setEmail('');
            setPhone('');
            setDate('');
            setType('');
            setSlot('');
            setMessage('');
    }


    return (
        <Layout>
            <div id="carouselExampleFade" className="carousel slide carousel-fade mt-5" data-ride="carousel"  data-interval="4000">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" style={{ height: '87vh' }} src={ban3} alt="First slide" />
                        <div className="carousel-caption d-flex h-100 align-items-center justify-content-center">
                            <h1> One Smile Dental Studio</h1>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" style={{ height: '87vh' }} src={ban2} alt="Second slide" />
                        <div className="carousel-caption d-flex h-100 align-items-center justify-content-center">
                            <h1> One Smile Dental Studio</h1>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" style={{ height: '87vh' }} src={ban1} alt="Third slide" />
                        <div className="carousel-caption d-flex h-100 align-items-center justify-content-center">
                            <h1> One Smile Dental Studio</h1>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                </a>
                <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                </a>
            </div>
            <h3 className='ml-4 mt-5'>Welcome to One Smile Dental Studio</h3><hr />

            <div className='row m-3'>
                <div className='col-lg-6 col-sm-12'>
                    <p className='text-justify'>One Smile Dental Studio is the leading dental healthcare provider in Ahmedabad with a team of highly experienced dental professionals.</p>
                    <p className='text-justify'>With One Smile Dental Studio, you are assured of visiting the Best clinic in Ahmedabad and acquiring the world class oral treatment and services under the supervision of Dr. Ashil. Our clinic adheres to the best standards of hygiene and safety to maintain the great value in dental care.</p>
                    <p className='text-justify'>At One Smile Dental Studio, we offer world class treatment and patient care with the latest and cutting edge technologies in dentistry. We provide a wide spectrum of Dental treatments right from Root Canal, Dental Implants, Teeth Cleaning, Invisalign, Cosmetic Dentistry to Full Mouth Rehabilitation and much more under one roof to cater to all your dental needs in the most hygienic environment. Our Patient friendly clinic and doctors will make you feel warm and comfortable. </p>
                </div>
                <div className='col-lg-6 col-sm-12'>
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="3000">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img className="d-block w-100" src={slide1} style={{ height: '55vh' }} alt="First slide" />
                            </div>
                            <div className="carousel-item">
                                <img className="d-block w-100" src={slide2} style={{ height: '55vh' }} alt="Second slide" />
                            </div>
                            <div className="carousel-item">
                                <img className="d-block w-100" src={slide3} style={{ height: '55vh' }} alt="Third slide" />
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="jumbotron jumbotron-fluid booking">
                <div className='container'>
                    <h3 className='text-center text-light'>Our Services</h3><hr style={{ width: '15vw' }} />
                    <div id="multi-item-example" className="carousel slide carousel-multi-item" data-ride="carousel" data-interval="2000">
                        <div className="carousel-inner" role="listbox">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="cards card2 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}}>Dental Implants</h5>
                                    </div>

                                    <div className="col-md-4 clearfix d-none d-md-block">
                                        <div className="cards card3 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}}>Teeth Whitining</h5>
                                    </div>

                                    <div className="col-md-4 clearfix d-none d-md-block">
                                        <div className="cards card4 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}}>Dental Bridges & Crowns</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                            <div className="row">
                                    <div className="col-md-4">
                                        <div className="cards card5 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}}>Wisdom Tooth Removal</h5>
                                    </div>

                                    <div className="col-md-4 clearfix d-none d-md-block">
                                        <div className="cards card6 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}}>Orthodontics</h5>
                                    </div>

                                    <div className="col-md-4 clearfix d-none d-md-block">
                                        <div className="cards card7 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}} >Laser Dentistry</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                            <div className="row">
                                    <div className="col-md-4">
                                        <div className="cards card8 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}}>Teeth Cleaning</h5>
                                    </div>

                                    <div className="col-md-4 clearfix d-none d-md-block">
                                        <div className="cards card9 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}}>Cosmetic Dentistry</h5>
                                    </div>

                                    <div className="col-md-4 clearfix d-none d-md-block">
                                        <div className="cards card1 mb-3 ml-auto mr-auto">
                                        </div>
                                        <h5 style={{textAlign: 'center'}}>Invisalign</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron jumbotron-fluid bg-white review-slider">
                <div className="container">
                    <h3 className='text-center'>Our Patients Who Love Us</h3><hr style={{ width: '15vw' }} />
                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner text-center">
                            <div className="carousel-item active">
                                <div className='reviews '>"Went here for my bi-annual teeth cleaning. Seamless procedure with all Covid Protocols in place, and that was definitely re-assuring. Have known Dr Ashil Shah for a few years now and his consultation and regular check ups have helped me in keeping a good oral hygiene."<br/><b>-Mohit Pratap </b></div>
                            </div>
                            <div className="carousel-item">
                                <div className='reviews '>"It was very Nice experience and very satisfactory result of treatment. Clinic is well maintained with all hygiene parameters. The way Dr. Ashil treat his patients is really awesome."<br/><b>-Milan Patel</b></div>
                            </div>
                            <div className="carousel-item">
                                <div className='reviews '>"Very nice dental setup.. area is good and neat n clean. They follow all hygiene protocols at clinic. Dr ashil is very gentle to his explanation and treatment… i undergone two wisdom teeth removal in same appointment… i was quite nervous at first but  when during treatment there was no discomfort… Also Maintenance & hygiene is very very impressive. I recommend this clinic for any of your dental need..!!!"<br/><b>-Raj Darbar</b></div>
                            </div>
                            <div className="carousel-item">
                                <div className='reviews '>"It is very difficult to find trustworthy place for dental treatments. One Smile is that trustworthy dental clinic. Dr Ashil listens and understands the problem thoroughly and provides correct advise for the treatment."<br/><b>-Harsh Shah</b></div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className=' jumbotron jumbotron-fluid booking' id='#booking'>
                <form onSubmit={handleSubmit} className='container'>
                    <h3 className="text-light fw-bold mb-4">Request an Appointment</h3>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <input value={name} type="text" className="form-control mb-3" required id="p1firstname" placeholder='Full Name' onChange={handleNameChange} />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                            <input value={email} type="email" className="form-control" required id="p2firstname" placeholder='Email' onChange={handleEmailChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-2 mb-3">
                            <select name="cars" id="cars" className='form-control'>
                                <option value="saab">+91 India</option>
                                <option value="opel">+1 US</option>
                                <option value="audi">Other</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-3">
                            <input value={phone} type="number" className="form-control " required id="p2lastname" placeholder='Phone Number' onChange={handlePhoneChange} />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                            <input value={date} placeholder="Date" className="form-control" required type="text" onFocus={(e) => e.currentTarget.type = 'date'} id="date" onChange={handleDateChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-6 mb-3'>
                            <label className="form-control">
                                <input type="radio" name="optradio" value='New Patient' checked onChange={handleTypeChange} /> A New Patient
                            </label>
                            <label className="form-control">
                                <input type="radio" name="a" checked value='Morning' onChange={handleSlotChange} /> Morning
                            </label>
                        </div>
                        <div className='col-6 mb-3'>
                            <label className="form-control">
                                <input type="radio" name="optradio" value='Existing Patient' onChange={handleTypeChange} /> Existing Patient
                            </label>
                            <label className="form-control">
                                <input type="radio" name="a" value='Evening' onChange={handleSlotChange} /> Evening
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <textarea value={message} className="form-control" placeholder='Message' onChange={handleMessageChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button type='submit' className="btn btn-outline-light">Send Enquiry</button>
                        </div>
                    </div>
                </form>
            </div >
        </Layout >
    )
}

export default Home