import React from "react";
import Layout from "../Layout";
import ashil from './DrAshilShah.jpg'
import jigar from './DrJIgarSodha.png'
import ankit from './Dr ankit shah.jpg'
import pratik from './Pratik upadhyay.jpg'
import vivek from './Vivekpatel.png'

const Team = () => {
    return (
        <Layout>
            <div className="Banner mb-5">
                <div className="container">
                    <h1 className="display-4">Our Team</h1>
                    <p className="lead">One Smile Dental Studio</p>
                </div>
            </div>
            <section className="team container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                <div className='row'>
                    <div className="col-md-4 d-flex ml-auto mr-auto">
                        <div className="member">
                            <div className="member-img">
                                <img src={ashil} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className="member">
                            <div className="member-info">
                                <h3>Dr. Ashil Shah</h3>
                                <span>BDS, MDS. (Periodontist, Dental Implantologist)</span>
                                <p className='text-justify'>Dr. Ashil Shah is a specialist dentist. He has received his master’s degree in the field of Periodontics and implantology from Gujarat university.
                                </p>
                                <p className='text-justify'>His goal is to educate, improve and help people of all ages to maintain their oral wellbeing. </p>

                                <p className='text-justify'>He follows evidence-based dental practice and feels that all patients need to be fully educated about their needs and essential treatment alternatives, so they can find the importance of their oral wellbeing and make educated decisions surrounding it.</p>

                                <p className='text-justify'>He is specialized in Implantology, Flap surgery, Bone grafting, regenerative therapy, Lasers and other blood less surgical procedures, Digital Smile Designing, Rct, Crown and Bridges and other interdisciplinary work involving other fields of dentistry.  </p>

                                <p className='text-justify'>He is also specialized in periodontal plastic surgeries like depigmentation, root coverages, vestibuloplasty, frenectomy etc...</p>

                                <p className='text-justify'>To his credit are several papers and publications; doing the most ethical work along with quality and comfort of the patient is his utmost motto during work.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-stretch">
                        <div className="member">
                            <div className="member-img">
                                <img src={pratik} className="img-fluid" alt="" style={{height: '40vh', width: '40vh'}} />
                            </div>
                            <div className="member-info">
                                <h4>DR. PRATIK UPADHYAY</h4>
                                <span>MDS (ROOT CANAL SPECIALIST)</span>
                                <p className='text-justify'>He received his masters' degree in the field of Conservative Dentistry & Endodontics. He specializes in painless and hassle-free root canal treatments making nervous patients feel at ease and comfortable throughout their dental procedures. He also specializes in esthetic dentistry that allows transformation of teeth shapes and smiles along with lightening color of teeth. He possesses thorough knowledge regarding retreatment and operating microscopes. He has been credited to publish papers regarding root canal treatment in renowned journals.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-stretch">
                        <div className="member">
                            <div className="member-img">
                                <img src={ankit} className="img-fluid" alt="" style={{height: '40vh', width: '40vh'}} />
                            </div>
                            <div className="member-info">
                                <h4>DR. ANKIT SHAH</h4>
                                <span>MDS (ORAL AND MAXILLOFACIAL SURGEON)</span>
                                <p className='text-justify'>Dr. Ankit Shah has done his Facio-maxillary Surgery from prestigious Institute of College of dental sciences, Davangere. He is expert in Exodontia procedures like painless extraction of tooth, removal of impacted third molar (wisdom tooth). He is also Specialist in Maxillofacial Trauma management & TMJ problems , Botox & Fillers for aesthetic correction for Facial Aesthetics. He's well versed with various fields of surgery like Oral Oncosurgery, Complicated Oral Implantology cases and Head and Neck Pathologies.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-stretch">
                        <div className="member">
                            <div className="member-img">
                                <img src={vivek} className="img-fluid" alt="" style={{height: '40vh', width: '40vh'}} />
                            </div>
                            <div className="member-info">
                                <h4>DR. VIVEK PATEL</h4>
                                <span>MDS (PERIODONTIST AND IMPLANTOLOGIST)</span>
                                <p className='text-justify'>Our smiles not only reflect our confidence, but they are also a source of it. Through Periodontics, he preserves the health of our patient’s teeth and periodontium, treat gum diseases and augment lost periodontium. This work helps them sustain their confidence, empowering them to make a difference in the world. Also Pyorrhoea or Periodontal disease affects your systemic health and quality of lifestyle. He is also a specialist in full mouth implant procedures.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-stretch">
                        <div className="member">
                            <div className="member-img">
                                <img src={jigar} className="img-fluid" alt="" style={{height: '40vh', width: '40vh'}} />
                            </div>
                            <div className="member-info">
                                <h4>DR. JIGAR SODHA</h4>
                                <span>MDS (PROSTHODONTIST)</span>
                                <p className='text-justify'>Tumours of jaw(s) can sometimes necessitate removal of part or full jaw(s). This may lead to difficulty in speaking, swallowing, and eating. This leads to compromised quality of life; confidence gets a great downfall and overall personality gets a toll. Maxillofacial prosthesis like Obturator, Guide Flange prosthesis, Velopharyngeal prosthesis can be of great aid to improve the quality of life in such patients.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Team