import React from "react";
import Layout from "../Layout";
import r from "./2.webp";
import aaa from "./3.webp";
import te from "./21.webp";
import bbb from "./23.webp";
import a from "./24.webp";
import aa from "./25.webp";
import b from "./26.webp";
import bb from "./27.webp";
import c from "./36.webp";
import cc from "./29.webp";
import ccc from "./30.webp";
import d from "./31.webp";
import dd from "./32.webp";
import ddd from "./33.webp";
import e from "./34.webp";


const Services = () => {
    return (
        <Layout>
            <div className="Banner mb-5">
                <div className="container">
                    <h1 className="display-4">Services</h1>
                    <p className="lead">One Smile Dental Studio</p>
                </div>
            </div>
            <h4 className='text-center container'>We're a full service dental clinic using state-of-the-art technology in the areas of family, restorative and cosmetic dentistry.<hr /></h4>
            <div className='container'>
                <div className='row'>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={te} alt='' />
                                <div className="card-body">
                                    <h5>Dental Implant</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Dental Implant</h5>
                                <p className='text-justify'>A Dental Implant is a surgical fixture, a titanium screw, which is placed into the jawbone to act as a replacement for the root of a missing tooth. Implants provide a strong foundation for fixed (permanent) or removable replacement teeth that are made to match your natural teeth. Dr. Ashil is highly experienced in dental implant procedure.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={r} alt='' />
                                <div className="card-body">
                                    <h5>Root Canal</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Root Canal</h5>
                                <p className='text-justify'>Root canal therapy can save a tooth that has become severely decayed or infected. When one undergoes a root canal, the inflamed or infected pulp is removed and the inside of the tooth is carefully cleaned and disinfected, then filled and sealed.Our primary goal is always to save your natural teeth whenever possible!</p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={a} alt='' />
                                <div className="card-body">
                                    <h5>Dental Bridges & Crowns</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Dental Bridges & Crowns</h5>
                                <p className='text-justify'>Crowns are full-coverage restorations that cover a tooth that is likely to break or is too damaged to be corrected with a traditional filling.  A dental bridge is intended to replace missing teeth by constructing a “bridge” between aaa neighboring teeth.  We work with trusted and experienced dental labs to create custom crowns and bridges for our patients.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={aa} alt='' />
                                <div className="card-body">
                                    <h5>Full mouth Rehab</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Full mouth Rehab </h5>
                                <p className='text-justify'>Full mouth rehabilitation might seem a complex process at first sight, but it is not, it is a restorative and recovery procedure to fix your teeth and rebuild your smile. The full mouth rehabilitation process includes implants, crowns and others depending upon the health of your mouth and teeth.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={aaa} alt='' />
                                <div className="card-body">
                                    <h5>Digital smile Design</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Digital smile Design</h5>
                                <p className='text-justify'>Digital Smile Design (DSD) is an amazing, innovative conceptual tool that we use to provide beautiful smiles. The process of Digital Smile Design involves the simulation of actual smile correction; which is shown to the patient in advance before the DSD process starts. This helps patients to know how their smile will be after the treatment.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={b} alt='' />
                                <div className="card-body">
                                    <h5>Gum Treatment</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Gum Treatment</h5>
                                <p className='text-justify'>Gum disease (Gingivitis) often develops slowly without causing any pain and can begin at just about any age. The warning signs for gum disease are bleeding gums, bad breath, red swollen and tender gums, loose teeth, pus discharge. These call for periodontal treatment like scaling, root planning, pocket reduction, cosmetic gum surgery. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={bb} alt='' />
                                <div className="card-body">
                                    <h5>Laser Dentistry</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Laser Dentistry</h5>
                                <p className='text-justify'>Our goal is to revolutionize the way we practice dentistry. To achieve this we always strive to provide the best dental technology to our patients. The laser technology enables us to accomplish dental procedures faster than ever, with less pain, less bleeding, and faster healing. We use laser for tooth removal, gum depigmentation, tooth sensitivity, dental implants, cavity treatment. </p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={bbb} alt='' />
                                <div className="card-body">
                                    <h5>Wisdom tooth Removal</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Wisdom tooth Removal</h5>
                                <p className='text-justify'>Wisdom tooth extraction is a surgical procedure to remove one or more wisdom teeth — the four permanent adult teeth located at the back corners of your mouth on the top and bottom. If a wisdom tooth doesn't have room to grow (impacted wisdom tooth), resulting in pain, infection or other dental problems, you'll likely need to have it pulled.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={c} style={{ height: '27vh' }} alt='' />
                                <div className="card-body">
                                    <h5>Teeth cleaning & whitening</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Teeth cleaning & whitening</h5>
                                <p className='text-justify'>Even brushing and flossing your teeth twice a day will not remove plaque in hard to reach areas such as between your teeth and gums. As a result, cavities and gum disease may occur. So it is important to have your teeth professionally cleaned at least every quarter. In order to enhance your look, we would prefer you to take teeth whitening to embrace your occasions.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={cc} alt='' />
                                <div className="card-body">
                                    <h5>Dental fillings</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Dental fillings</h5>
                                <p className='text-justify'>Dental filling is a restorative treatment which addresses a cavity. When you develop a cavity, plaque has eaten away at your tooth’s tissue, forming a hole. As the hole deepens, you may experience sensitivity due to exposed nerves. You also run the risk of bacterial entry and infection within your tooth. Dental fillings will cover the hole through composite material.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={ccc} alt='' />
                                <div className="card-body">
                                    <h5>Cosmetic Dentistry</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Cosmetic Dentistry</h5>
                                <p className='text-justify'>If your teeth are stained, discolored, worn, chipped, broken, misaligned, misshapen, or have gaps between them, modern cosmetic dentistry can give you a better smile. A “smile makeover” improves the appearance of your smile We at One Smile, offer various cosmetic dentistry treatments like whitening, bonding, crowns, veneers, inlays and onlays and much more. </p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={d} alt='' />
                                <div className="card-body">
                                    <h5>Orthodontics (Braces)</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Orthodontics (Braces)</h5>
                                <p className='text-justify'>Metal and Ceramic Braces are a solution to straighten your teeth that are misaligned. Metal braces are made of high-grade stainless and use metal brackets and archwires. Ceramic dental braces are made of a clear ceramic material, and are therefore less visible on your teeth than metal braces. Based upon the patient’s need, braces are used.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={dd} alt='' />
                                <div className="card-body">
                                    <h5>Aligners</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Aligners</h5>
                                <p className='text-justify'>We partner with Invisalign and other clear aligner brands to help you get straight teeth without metal brackets.  Clear orthodontics is a great solution for your crooked teeth without the look of ugly wires and the daily challenges of brushing and flossing.  Just wear a series of clear, removable aligners custom made for you and you’re on your way to your dream smile.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={ddd} alt='' />
                                <div className="card-body">
                                    <h5>Children Dentistry</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Children Dentistry</h5>
                                <p className='text-justify'>Humans start teething when they are as young as 6 months old and develop their set of primary ‘milk’ teeth. At the age of 6-7 years, humans start losing their milk teeth and the permanent set of teeth start emerging. If proper care is not taken the problems can emerge which will last a lifetime. We provide best dental solutions for your kids with our experienced pediatric dentists.</p>
                            </div>
                        </div>
                    </div>
                    <div className="cardBox col-lg-4 col-md-12 col-sm-12 mb-3">
                        <div className="card11">
                            <div className="front">
                                <img src={e} alt='' />
                                <div className="card-body">
                                    <h5>Tooth Extraction</h5>
                                </div>
                            </div>
                            <div className="back">
                                <h5>Tooth Extraction</h5>
                                <p className='text-justify'>Tooth extractions may be necessary at times. Whether it is a wisdom tooth or a tooth that’s too severely damaged or decayed to be repaired, saying goodbye to your teeth is not easy.  At One Smile, we’ll do everything in our power to make your experience as least painful and stressful as possible.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default Services